<template>
<!--  侧边栏-->
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        background-color="#fff"
        text-color="#555"
        active-text-color="#333"
        unique-opened
        :collapse-transition="false"
        mode="vertical"
      >
      <!--   sidebar-item 组件     -->
        <sidebar-item v-for="route in showRoutes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
    <!-- <div>
      {{showRoutes}}
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters([
      'permission_addRoutes',
      'activeModel',
      'sidebar'
    ]),
    showRoutes(){
      return this.permission_addRoutes.filter(r=>{
        return r.meta.menuFrom == this.activeModel
      })
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set

      /*if (meta.activeMenu) {
        return meta.activeMenu
      }*/
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    },
  },
  mounted(){



  },
}
</script>
